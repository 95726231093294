import React, { useEffect, useState } from 'react';
import { Toolbar } from './Toolbar';
import axios from 'axios';

const backendURL = 'https://haciendaorillas.live/backend';

export function ProfileForm() {
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const userId = localStorage.getItem('userId');
        if (!userId) {
          setError('ID de usuario no encontrado.');
          return;
        }

        const response = await axios.get(`${backendURL}/api/usuarios/profile/${userId}`);
        setUser({ ...response.data, id: userId }); 

        const imageResponse = await axios.get(`${backendURL}/api/usuarios/obtener-imagen?userId=${userId}`, {
          responseType: 'arraybuffer',
        });

        if (imageResponse.status === 200) {
          const base64Image = `data:image/jpeg;base64,${btoa(
            new Uint8Array(imageResponse.data)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          )}`;
          setPreviewUrl(base64Image);
        } else {
          setPreviewUrl(''); 
        }
      } catch (err) {
        console.error('Error al obtener el perfil:', err);
        setError('Error al cargar el perfil. Por favor, intenta más tarde.');
      }
    };

    fetchProfile();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) { 
        setError('La imagen no debe superar los 2 MB.');
        return;
      }
      setError('');
      setSelectedImage(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpload = async () => {
    if (!selectedImage) {
      alert('Por favor selecciona una imagen antes de guardar.');
      return;
    }

    const formData = new FormData();
    formData.append('archivo', selectedImage);

    try {
      if (!user.id) {
        throw new Error('ID del usuario no encontrado.');
      }

      const response = await axios.post(
        `${backendURL}/api/usuarios/subir-imagen?userId=${user.id}`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      if (response.status === 200) {
        alert('Imagen de perfil actualizada con éxito.');
        setIsEditModalOpen(false);

        const base64Image = `data:image/jpeg;base64,${response.data.imagenPerfil}`;
        setPreviewUrl(base64Image);
      } else {
        alert('Error al subir la imagen.');
      }
    } catch (err) {
      console.error('Error al subir la imagen:', err);
      alert('Error al subir la imagen.');
    }
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedImage(null); 
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!user) {
    return <div className="text-gray-500">Cargando perfil...</div>;
  }

  return (
    <div className="w-full min-h-screen bg-gray-100">
      <div className="w-full bg-gray-800 text-white px-4 py-2 shadow-lg">
        <Toolbar userRole={user.rol} />
      </div>
      <div className="flex items-center justify-center min-h-screen">
        <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-6">
          <h2 className="text-xl font-bold mb-4">Perfil del Usuario</h2>
          <div className="mb-6 text-center">
            {previewUrl ? (
              <img
                src={previewUrl}
                alt="Imagen de perfil"
                className="w-32 h-32 rounded-full mx-auto shadow-md object-cover cursor-pointer"
                onClick={() => setIsImageModalOpen(true)}
              />
            ) : (
              <div
                className="w-32 h-32 rounded-full mx-auto bg-gray-300 flex items-center justify-center cursor-pointer"
                onClick={() => setIsImageModalOpen(true)} 
              >
                <span className="text-gray-500">Sin Imagen</span>
              </div>
            )}
            <button
              type="button"
              onClick={() => setIsEditModalOpen(true)}
              className="mt-4 inline-block text-blue-500 hover:underline"
            >
              Cambiar Foto de Perfil
            </button>
          </div>

          <div className="space-y-4">
            <div>
              <strong className="block text-gray-700">Nombre:</strong>
              <p>{user.nombre}</p>
            </div>
            <div>
              <strong className="block text-gray-700">Correo:</strong>
              <p>{user.correo}</p>
            </div>
            <div>
              <strong className="block text-gray-700">Edad:</strong>
              <p>{user.edad}</p>
            </div>
            <div>
              <strong className="block text-gray-700">Celular:</strong>
              <p>{user.celular}</p>
            </div>
            <div>
              <strong className="block text-gray-700">Rol:</strong>
              <p>{user.rol}</p>
            </div>
          </div>
        </div>
      </div>

      {isImageModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={closeImageModal}
        >
          <div
            className="bg-transparent rounded-full p-4"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={closeImageModal}
              className="text-red-500 absolute top-4 right-4 text-xl font-bold"
            >
              ×
            </button>
            <img
              src={previewUrl}
              alt="Imagen ampliada"
              className="w-[300px] h-[300px] object-cover rounded-full"
            />
          </div>
        </div>
      )}

      {isEditModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
            <h2 className="text-lg font-bold mb-4">Subir Nueva Foto</h2>
            <input
              type="file"
              accept="image/*"
              className="w-full mb-4"
              onChange={handleImageChange}
            />
            {selectedImage && (
              <img
                src={previewUrl}
                alt="Vista previa"
                className="w-32 h-32 rounded-full mx-auto mb-4 object-cover"
              />
            )}
            <div className="flex justify-between">
              <button
                onClick={handleImageUpload}
                className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
              >
                Guardar Cambios
              </button>
              <button
                onClick={closeEditModal}
                className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
