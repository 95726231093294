import React, { useEffect, useState } from 'react';
import { Toolbar } from './Toolbar';
import axios from 'axios';

const backendURL = 'https://haciendaorillas.live/backend';

export function SolicitudesForm() {
  const [solicitudes, setSolicitudes] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [roles, setRoles] = useState([]);
  const [currentUserRole, setCurrentUserRole] = useState('');
  const [selectedRoles, setSelectedRoles] = useState({});

  useEffect(() => {
    const fetchCurrentUserRole = async () => {
      try {
        const userId = localStorage.getItem('userId');
        if (!userId) {
          console.error('ID de usuario no encontrado.');
          return;
        }

        const response = await axios.get(`${backendURL}/api/usuarios/profile/${userId}`);
        setCurrentUserRole(response.data.rol);
      } catch (err) {
        console.error('Error al obtener el perfil del usuario:', err);
      }
    };

    const fetchSolicitudes = async () => {
      try {
        const response = await axios.get(`${backendURL}/api/usuarios/solicitudes`);
        setSolicitudes(response.data.filter(solicitud => solicitud.estado === 'pendiente'));
      } catch (error) {
        console.error('Error al obtener las solicitudes:', error);
      }
    };

    const fetchUsuarios = async () => {
      try {
        const response = await axios.get(`${backendURL}/api/usuarios/traer-usuarios`);
        setUsuarios(response.data.filter(usuario => usuario.rol === 'user' || usuario.rol === 'moderator' && usuario.estado !== 'pendiente'));

      } catch (error) {
        console.error('Error al obtener los usuarios:', error);
      }
    };

    const fetchRoles = async () => {
      try {
        const response = await axios.get(`${backendURL}/api/rol/roles`);
        setRoles(response.data);
      } catch (error) {
        console.error('Error al obtener roles:', error);
      }
    };

    const initializeData = async () => {
      await fetchCurrentUserRole();
      await fetchSolicitudes();
      await fetchRoles();
      if (currentUserRole === 'admin') {
        await fetchUsuarios();
      }
    };

    initializeData();
  }, [currentUserRole]);

  const handleUpdateStatus = async (id, estado) => {
    try {
      const response = await axios.post(`${backendURL}/api/usuarios/actualizar-estado/${id}`, { estado });
      if (response.status === 200) {
        setSolicitudes(solicitudes.filter(solicitud => solicitud._id !== id));
        if (estado === 'activo' && selectedRoles[id].hasChanged) {
          updateRole(id, selectedRoles[id].selectedRol);
        }
      }
    } catch (error) {
      console.error(`Error al actualizar el estado del usuario a ${estado}:`, error);
    }
  };

  const updateRole = async (id, rol) => {
    try {
      const response = await axios.patch(`${backendURL}/api/rol/actualizar-rol/${id}`, { rol });
      if (response.status === 200) {
        setUsuarios(usuarios.map(usuario => {
          if (usuario._id === id) {
            return { ...usuario, rol };
          }
          return usuario;
        }));
        setSelectedRoles(prev => {
          const newState = { ...prev };
          delete newState[id];
          return newState;
        });
      }
    } catch (error) {
      console.error('Error al actualizar el rol del usuario:', error);
    }
  };

  const handleRoleChange = (id, rol) => {
    setSelectedRoles(prev => ({
      ...prev,
      [id]: { ...prev[id], selectedRol: rol, hasChanged: true }
    }));
  };

  const handleChangeOnDropdown = (id, event) => {
    handleRoleChange(id, event.target.value);
  };

  const handleDeleteUser = async id => {
    try {
      const response = await axios.delete(`${backendURL}/api/usuarios/${id}`);
      if (response.status === 200) {
        setUsuarios(usuarios.filter(usuario => usuario._id !== id));
      }
    } catch (error) {
      console.error('Error al eliminar el usuario:', error);
    }
  };

  return (
    <div className="h-screen flex flex-col bg-gradient-to-b from-blue-50 via-white to-blue-100">
      <Toolbar userRole={currentUserRole} />

      <div className="p-6">
        <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
          <h2 className="text-2xl font-bold mb-4 text-blue-600">Solicitudes de Registro</h2>
          <table className="min-w-full bg-white border border-gray-200 rounded-lg overflow-hidden">
            <thead className="bg-blue-100 text-blue-600">
              <tr>
                <th className="py-2 px-4 text-left">Nombre</th>
                <th className="py-2 px-4 text-left">Correo</th>
                <th className="py-2 px-4 text-center">Rol</th>
                <th className="py-2 px-4 text-center">Edad</th>
                <th className="py-2 px-4 text-center">Celular</th>
                <th className="py-2 px-4 text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {solicitudes.map(registro => (
                <tr key={registro._id} className="hover:bg-blue-50">
                  <td className="py-2 px-4 text-left">{registro.nombre}</td>
                  <td className="py-2 px-4 text-left">{registro.correo}</td>
                  <td className="py-2 px-4 text-center">
                    <select
                      value={selectedRoles[registro._id] ? selectedRoles[registro._id].selectedRol : registro.rol}
                      onChange={(e) => handleRoleChange(registro._id, e.target.value)}
                      className="bg-white border border-gray-300 rounded px-2 py-1 w-full"
                    >
                      {roles.map(rol => (
                        <option key={rol._id} value={rol._id}>
                          {rol.nombre}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="py-2 px-4 text-center">{registro.edad}</td>
                  <td className="py-2 px-4 text-center">{registro.celular}</td>
                  <td className="py-2 px-4 text-center flex justify-center gap-2">
                    <button
                      onClick={() => handleUpdateStatus(registro._id, 'activo')}
                      className="bg-green-500 hover:bg-green-400 text-white py-1 px-3 rounded"
                    >
                      Aceptar
                    </button>
                    <button
                      onClick={() => handleUpdateStatus(registro._id, 'denegado')}
                      className="bg-red-500 hover:bg-red-400 text-white py-1 px-3 rounded"
                    >
                      Rechazar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {currentUserRole === 'admin' && (
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h2 className="text-2xl font-bold mb-4 text-blue-600">Usuarios Registrados</h2>
            <table className="min-w-full bg-white border border-gray-200 rounded-lg overflow-hidden">
              <thead className="bg-blue-100 text-blue-600">
                <tr>
                  <th className="py-2 px-4 text-left">Nombre</th>
                  <th className="py-2 px-4 text-left">Correo</th>
                  <th className="py-2 px-4 text-center">Rol</th>
                  <th className="py-2 px-4 text-center">Edad</th>
                  <th className="py-2 px-4 text-center">Celular</th>
                  <th className="py-2 px-4 text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {usuarios.map(usuario => (
                  <tr key={usuario._id} className="hover:bg-blue-50">
                    <td className="py-2 px-4 text-left">{usuario.nombre}</td>
                    <td className="py-2 px-4 text-left">{usuario.correo}</td>
                    <td className="py-2 px-4 text-center">
                      <select
                        value={selectedRoles[usuario._id] ? selectedRoles[usuario._id].selectedRol : usuario.rol}
                        onChange={(e) => handleRoleChange(usuario._id, e.target.value)}
                        className="bg-white border border-gray-300 rounded px-2 py-1 w-full"
                      >
                        {roles.map(rol => (
                          <option key={rol._id} value={rol._id}>
                            {rol.nombre}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="py-2 px-4 text-center">{usuario.edad}</td>
                    <td className="py-2 px-4 text-center">{usuario.celular}</td>
                    <td className="py-2 px-4 text-center flex justify-center gap-2">
                      <button onClick={() => handleDeleteUser(usuario._id)} className="bg-red-500 hover:bg-red-400 text-white py-1 px-3 rounded">
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
