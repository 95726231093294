import React from 'react';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { Droplets, Sun, Thermometer, TestTube } from 'lucide-react';

const iconMap = {
  Droplets,
  Sun,
  Thermometer,
  TestTube,
};

export function SensorCard({ sensor }) {
  const Icon = iconMap[sensor.icon];

  return (
    <div className="bg-white rounded-2xl shadow-lg p-6 hover:shadow-2xl transition-shadow border-t-4" style={{ borderColor: sensor.color }}>
        <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-3">
                <div className={`p-3 rounded-full`} style={{ backgroundColor: sensor.color }}>
                    <Icon className="w-6 h-6 text-white" />
                </div>
                <h3 className="text-lg font-semibold text-gray-800">{sensor.name}</h3>
            </div>
            <div className="text-right">
                <p className="text-2xl font-bold text-gray-900">
                    {(sensor.data[sensor.data.length - 1]?.value ?? 0).toFixed(1)}
                    <span className="text-sm ml-1 text-gray-500">{sensor.unit}</span>
                </p>
                <p className="text-sm text-gray-500">Último valor</p>
            </div>
        </div>
        <div className="h-[200px] mt-4">
            <ResponsiveContainer width="100%" height="100%">
                <BarChart data={sensor.data}>
                    <XAxis dataKey="timestamp" stroke="#94a3b8" />
                    <YAxis stroke="#94a3b8" />
                    <Tooltip
                        contentStyle={{
                            backgroundColor: 'rgba(255, 255, 255, 0.95)',
                            border: 'none',
                            borderRadius: '8px',
                            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
                        }}
                        labelFormatter={(timestamp) => `Hora: ${timestamp}`}
                    />
                    <Bar dataKey="value" fill={sensor.color} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    </div>
  );
}
