import React from 'react';
import { useNavigate } from 'react-router-dom';

export function Toolbar({ userRole }) {
  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate('/profileform');
  };

  const handleRecordsClick = () => {
    navigate('/solicitudesform');
  };

  const handleLogout = () => {
    localStorage.clear(); 
    navigate('/'); 
  };

  return (
    <div className="bg-gray-800 text-white px-4 py-2 flex items-center justify-between shadow-lg">
      <h1
        onClick={() => navigate('/dashboard')}
        className="text-lg font-bold cursor-pointer"
      >
        Dashboard
      </h1>
      <div className="flex items-center space-x-4">
        {(userRole === 'admin' || userRole === 'moderator') && (
          <button
            onClick={handleRecordsClick}
            className="bg-blue-600 px-4 py-2 rounded-lg hover:bg-blue-500 focus:outline-none"
          >
            Registros
          </button>
        )}
        <button
          onClick={handleProfileClick}
          className="bg-blue-600 px-4 py-2 rounded-lg hover:bg-blue-500 focus:outline-none"
        >
          Profile
        </button>
        <button
          onClick={handleLogout}
          className="bg-red-600 px-4 py-2 rounded-lg hover:bg-red-500 focus:outline-none"
        >
          Cerrar Sesión
        </button>
      </div>
    </div>
  );
}