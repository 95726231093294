import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const backendURL = 'https://haciendaorillas.live/backend';

export function RegisterForm() {
  const [nombre, setNombre] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [edad, setEdad] = useState('');
  const [correo, setCorreo] = useState('');
  const [celular, setCelular] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden.');
      return;
    }

    const userData = {
      nombre,
      password,
      edad,
      correo,
      celular,
      tokenRecuperacion: null,
      tokenExpiracion: null,
      rol: null,
      estado: "pendiente",
      fotoPerfil: null
    };

    try {
      const response = await axios.post(`${backendURL}/api/usuarios/register`, userData);

      if (response.status === 201) {
        setSuccess('Registro exitoso. Ahora puedes iniciar sesión.');
        setNombre('');
        setPassword('');
        setConfirmPassword('');
        setEdad('');
        setCorreo('');
        setCelular('');

        setTimeout(() => {
          navigate('/');
        }, 2000);
      } else {
        setError('Error en el registro. Intenta de nuevo.');
      }
    } catch (err) {
      setError('Error al registrar. Verifica los datos.');
      console.error(err);
    }
  };

  return (
    <div className="h-screen flex items-center justify-center bg-gradient-to-b from-blue-50 via-blue-100 to-blue-200">
      <div className="bg-white rounded-2xl shadow-2xl p-8 max-w-md w-full">
        {error && <div className="text-red-500 text-sm mb-4">{error}</div>}
        {success && <div className="text-green-500 text-sm mb-4">{success}</div>}
        <form onSubmit={handleRegister}>
          <h2 className="text-3xl font-bold text-blue-600 mb-6">Registrarse</h2>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600" htmlFor="nombre">
              Nombre completo
            </label>
            <input
              id="nombre"
              type="text"
              className="w-full mt-1 p-3 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
              placeholder="Ingresa tu nombre"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600" htmlFor="correo">
              Correo electrónico
            </label>
            <input
              id="correo"
              type="email"
              className="w-full mt-1 p-3 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
              placeholder="Ingresa tu correo"
              value={correo}
              onChange={(e) => setCorreo(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600" htmlFor="edad">
              Edad
            </label>
            <input
              id="edad"
              type="number"
              className="w-full mt-1 p-3 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
              placeholder="Ingresa tu edad"
              value={edad}
              onChange={(e) => setEdad(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600" htmlFor="celular">
              Número de celular
            </label>
            <input
              id="celular"
              type="text"
              className="w-full mt-1 p-3 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
              placeholder="Ingresa tu número de celular"
              value={celular}
              onChange={(e) => setCelular(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600" htmlFor="password">
              Contraseña
            </label>
            <input
              id="password"
              type="password"
              className="w-full mt-1 p-3 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
              placeholder="Crea una contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600" htmlFor="confirmPassword">
              Confirmar contraseña
            </label>
            <input
              id="confirmPassword"
              type="password"
              className="w-full mt-1 p-3 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
              placeholder="Confirma tu contraseña"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600 transition-colors"
          >
            Registrarse
          </button>
          <div className="text-center mt-4">
            <p className="text-sm text-gray-600">
              ¿Ya tienes una cuenta?{' '}
              <button
                type="button"
                className="text-blue-500 hover:underline"
                onClick={() => navigate('/')}
              >
                Inicia sesión
              </button>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}