import React, { useState, useEffect } from 'react';
import { Toolbar } from './Toolbar';
import { SensorCard } from './SensorCard';
import { useSensorData } from '../hooks/useSensorData';
import axios from 'axios';

const backendURL = 'https://haciendaorillas.live/backend';

export function Dashboard() {
  const [user, setUser] = useState(null);
  const { sensors, loading, error } = useSensorData();

  useEffect(() => {
    const fetchUserData = async () => {
      const userId = localStorage.getItem('userId');
      if (userId) {
        try {
          const response = await axios.get(`${backendURL}/api/usuarios/profile/${userId}`);
          setUser(response.data); 
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    return <div className="text-gray-500">Cargando datos de sensores...</div>;
  }

  if (error) {
    return <div className="text-red-500">Error al cargar los datos: {error}</div>;
  }

  return (
    <div className="h-screen flex flex-col bg-gradient-to-b from-blue-100 via-white to-blue-50">
        <Toolbar userRole={user?.rol} />
        <div className="p-6">
            <h1 className="text-3xl font-bold text-blue-600 mb-6">Panel de Sensores</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {sensors.map((sensor) => (
                    <SensorCard key={sensor.id} sensor={sensor} />
                ))}
            </div>
        </div>
    </div>
  );
}