import { useState, useEffect } from 'react';
import axios from 'axios';

const backendURL = 'https://haciendaorillas.live/backend';

export function useSensorData() {
  const [sensors, setSensors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendURL}/api/datasensor`);

        if (!response.data || response.data.length === 0) {
          throw new Error("No se encontraron datos en la respuesta");
        }
        
        const transformedData = [
          {
            id: '1',
            name: 'Humedad del Ambiente',
            unit: '%',
            icon: 'Droplets',
            color: '#3B82F6', 
            data: response.data.map(d => ({ timestamp: d.time, value: d.humedad }))
          },
          {
            id: '2',
            name: 'Claridad del Ambiente',
            unit: 'lux',
            icon: 'Sun',
            color: '#F59E0B', 
            data: response.data.map(d => ({ timestamp: d.time, value: d.luminosidad }))
          },
          {
            id: '3',
            name: 'Temperatura del Ambiente',
            unit: '°C',
            icon: 'Thermometer',
            color: '#EF4444', 
            data: response.data.map(d => ({ timestamp: d.time, value: d.temperatura }))
          },
          {
            id: '4',
            name: 'Humedad del Suelo',
            unit: '%',
            icon: 'TestTube',
            color: '#10B981',
            data: response.data.map(d => ({ timestamp: d.time, value: d.humedadSuelo }))
          }
        ];
        setSensors(transformedData);
        setLoading(false);
      } catch (err) {
        console.error("Error al obtener los datos:", err);
        setError("Failed to fetch sensor data");
        setLoading(false);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 14400000);

    return () => clearInterval(interval);
  }, []);

  return { sensors, loading, error };
}