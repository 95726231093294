import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { LoginForm } from './components/LoginForm';
import { RegisterForm } from './components/RegisterForm';
import { Dashboard } from './components/Dashboard';
import { PasswordForm } from './components/PasswordForm';
import { ProfileForm } from './components/ProfileForm'; 
import { SolicitudesForm } from './components/SolicitudesForm';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/passwordform" element={<PasswordForm />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profileform" element={<ProfileForm />} />
        <Route path="/solicitudesform" element={<SolicitudesForm />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;