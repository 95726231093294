import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';

const backendURL = 'https://haciendaorillas.live/backend';

export function PasswordForm() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden.');
      return;
    }

    try {
      const response = await axios.post(`${backendURL}/api/email/update-password`, { token, newPassword: password });
      if (response.status === 200) {
        setSuccess('Contraseña actualizada exitosamente.');
        setTimeout(() => navigate('/'), 3000);
      } else {
        setError('Error al actualizar la contraseña.');
      }
    } catch (err) {
      setError('Error al procesar la solicitud. Intenta más tarde.');
      console.error(err);
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-6 max-w-md mx-auto mt-10">
      {error && <div className="text-red-500 text-sm mb-4">{error}</div>}
      {success && <div className="text-green-500 text-sm mb-4">{success}</div>}
      <form onSubmit={handleUpdatePassword}>
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Actualizar contraseña</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600" htmlFor="password">
            Nueva contraseña
          </label>
          <input
            id="password"
            type="password"
            className="w-full mt-1 p-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
            placeholder="Ingresa tu nueva contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600" htmlFor="confirmPassword">
            Confirmar contraseña
          </label>
          <input
            id="confirmPassword"
            type="password"
            className="w-full mt-1 p-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
            placeholder="Confirma tu nueva contraseña"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition-colors">
          Actualizar contraseña
        </button>
      </form>
    </div>
  );
}