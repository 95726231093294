import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const backendURL = 'https://haciendaorillas.live/backend';;

export function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showReset, setShowReset] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    try {
      const response = await axios.post(`${backendURL}/api/usuarios/login`, {
        email,
        password,
      });
      if (response.status === 200 && response.data.estado === 'activo') {
        const { userId, token } = response.data;
        localStorage.setItem('userId', userId); 
        localStorage.setItem('authToken', token); 
        navigate('/dashboard'); 
      } else if (response.data.estado !== 'activo') {
        setError('Tu cuenta no está activa. Contacta con soporte.');
      } else {
        setError('Credenciales inválidas. Intenta de nuevo.');
      }
    } catch (err) {
      setError('Error al iniciar sesión. Intenta más tarde.');
      console.error(err);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    try {
      const response = await axios.post(`${backendURL}/api/email/reset-password`, {
        email: resetEmail,
      });
      if (response.status === 200) {
        setSuccessMessage('Enlace de recuperación enviado a tu correo.');
        setResetEmail('');
        setShowReset(false);
      } else {
        setError('Error al enviar el enlace de recuperación.');
      }
    } catch (err) {
      setError('Error al procesar la solicitud. Intenta más tarde.');
      console.error(err);
    }
  };

  return (
    <div className="h-screen flex items-center justify-center bg-gradient-to-b from-blue-50 via-blue-100 to-blue-200">
      <div className="bg-white rounded-2xl shadow-2xl p-8 max-w-md w-full">
        {error && <div className="text-red-500 text-sm mb-4">{error}</div>}
        {successMessage && <div className="text-green-500 text-sm mb-4">{successMessage}</div>}

        {!showReset ? (
          <form onSubmit={handleLogin}>
            <h2 className="text-3xl font-bold text-blue-600 mb-6">Iniciar sesión</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-600" htmlFor="email">
                Correo electrónico
              </label>
              <input
                id="email"
                type="email"
                className="w-full mt-1 p-3 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
                placeholder="Ingresa tu correo"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-600" htmlFor="password">
                Contraseña
              </label>
              <input
                id="password"
                type="password"
                className="w-full mt-1 p-3 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
                placeholder="Ingresa tu contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="flex justify-between items-center mb-4">
              <button
                type="button"
                className="text-sm text-blue-500 hover:underline"
                onClick={() => setShowReset(true)}
              >
                ¿Olvidaste tu contraseña?
              </button>
              <button
                type="button"
                className="text-sm text-blue-500 hover:underline"
                onClick={() => navigate('/register')}
              >
                Registrarse
              </button>
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600 transition-colors"
            >
              Iniciar sesión
            </button>
          </form>
        ) : (
          <form onSubmit={handleResetPassword}>
            <h2 className="text-3xl font-bold text-blue-600 mb-6">Recuperar contraseña</h2>
            <p className="text-gray-600 text-sm mb-4">
              Ingresa tu correo electrónico para recibir un enlace de recuperación.
            </p>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-600" htmlFor="reset-email">
                Correo electrónico
              </label>
              <input
                id="reset-email"
                type="email"
                className="w-full mt-1 p-3 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
                placeholder="Ingresa tu correo"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600 transition-colors mb-4"
            >
              Enviar enlace
            </button>
            <button
              type="button"
              className="text-sm text-blue-500 hover:underline"
              onClick={() => setShowReset(false)}
            >
              Volver al inicio de sesión
            </button>
          </form>
        )}
      </div>
    </div>
  );
}
